import React from 'react';
import SEO from 'components/seo';
import { JumprunIcon } from 'components/icons';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const Wrapper = styled.div`
  ${tw`flex items-center justify-center flex-col h-screen`}
`;

const Main = tw.div`
  p-6 bg-gray-100 rounded-lg shadow-2xl
`;

const Heading = tw.h1`
  text-2xl text-gray-500 mt-5
`;

const Text = tw.p`
  text-xl text-gray-700 my-4
`;

const Logo = styled.div`
  ${tw`flex items-center justify-around`}
  svg,
  img {
    width: 64px;
  }
`;

const StyledJumprunIcon = styled(JumprunIcon)`
  ${tw`bg-blue-500`}
`;

const Footer = styled.footer`
  ${tw`flex justify-center`}
  svg {
    width: 30px;
    path {
      &:hover {
        fill: palevioletred;
      }
    }
  }
`;

const IndexPage = () => (
  <Wrapper>
    <SEO title="Home" />
    <Main>
      <Logo>
        <StyledJumprunIcon />
      </Logo>
      <Heading>jump.run</Heading>
      <Footer>
        <a
          href="https://github.com/denvash/gatsby-styled-tailwind-storybook-starter"
          target="_blank"
          rel="noopener noreferrer"></a>
      </Footer>
    </Main>
  </Wrapper>
);

export default IndexPage;
