import React from 'react';

const JumprunIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1"
    viewBox="0 0 452 462">
    <defs>
      <path
        id="b"
        d="M59.092 26.071c-18.478 0-33.457 14.933-33.457 33.354S40.615 92.78 59.092 92.78c18.478 0 33.458-14.933 33.458-33.354 0-18.42-14.98-33.354-33.458-33.354zm220.471 192.832l.107-.043c.213-.025.424-.031.635-.019a12.025 12.025 0 012.367-.234c6.809 0 12.328 5.662 12.328 12.646 0 4.291-2.083 8.083-5.269 10.37-.092.146-.195.279-.307.394l-.828.33c-1 .562-2.085.988-3.23 1.25-37.59 14.245-76.532 18.138-117.868 3.67-10.37-4.102-19.827-7.54-29.096-12.839-18.64-10.655-35.326-25.104-48.77-41.5-10.987-13.4-19.47-29.385-27.174-46.737-5.372-11.251-10.707-26.125-25.84-32.26-.517-.246-.804-.516-.882-.807C14.715 104.084 0 83.236 0 58.962 0 26.398 26.48 0 59.146 0 91.81 0 118.29 26.398 118.29 58.962c0 16.152-6.515 30.787-17.067 41.436l-.095.102-.062.054a58.898 58.898 0 01-3.37 3.126c-12.657 12.417-13.36 22.026-10.13 34.696 30.427 74.937 111.015 113.243 189.719 81.468l1.293-.535-.334.138-.026.01.801-.33.199-.082.344-.142z"></path>
      <filter
        id="a"
        width="111.9%"
        height="113.7%"
        x="-5.9%"
        y="-6.8%"
        filterUnits="objectBoundingBox">
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="4"
          result="shadowSpreadOuter1"></feMorphology>
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3.5"></feGaussianBlur>
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"></feComposite>
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"></feColorMatrix>
      </filter>
      <path
        id="d"
        d="M59.092 26.071c-18.478 0-33.457 14.933-33.457 33.354S40.615 92.78 59.092 92.78c18.478 0 33.458-14.933 33.458-33.354 0-18.42-14.98-33.354-33.458-33.354zm220.471 192.832l.107-.043c.213-.025.424-.031.635-.019a12.025 12.025 0 012.367-.234c6.809 0 12.328 5.662 12.328 12.646 0 4.291-2.083 8.083-5.269 10.37-.092.146-.195.279-.307.394l-.828.33c-1 .562-2.085.988-3.23 1.25-37.59 14.245-76.532 18.138-117.868 3.67-10.37-4.102-19.827-7.54-29.096-12.839-18.64-10.655-35.326-25.104-48.77-41.5-10.987-13.4-19.47-29.385-27.174-46.737-5.372-11.251-10.707-26.125-25.84-32.26-.517-.246-.804-.516-.882-.807C14.715 104.084 0 83.236 0 58.962 0 26.398 26.48 0 59.146 0 91.81 0 118.29 26.398 118.29 58.962c0 16.152-6.515 30.787-17.067 41.436l-.095.102-.062.054a58.898 58.898 0 01-3.37 3.126c-12.657 12.417-13.36 22.026-10.13 34.696 30.427 74.937 111.015 113.243 189.719 81.468l1.293-.535-.334.138-.026.01.801-.33.199-.082.344-.142z"></path>
      <filter
        id="c"
        width="111.9%"
        height="113.7%"
        x="-5.9%"
        y="-6.8%"
        filterUnits="objectBoundingBox">
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="4"
          result="shadowSpreadOuter1"></feMorphology>
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3.5"></feGaussianBlur>
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"></feComposite>
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"></feColorMatrix>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" strokeLinejoin="round">
      <g transform="translate(-29 2) scale(-1 1) rotate(10 -147.66 -1665.448)">
        <use fill="#000" filter="url(#a)" xlinkHref="#b"></use>
        <use fill="#292828" stroke="#292828" strokeWidth="5" xlinkHref="#b"></use>
      </g>
      <g transform="translate(-29 2) scale(1 -1) rotate(10 2726.727 1020.871)">
        <use fill="#000" filter="url(#c)" xlinkHref="#d"></use>
        <use fill="#292828" stroke="#292828" strokeWidth="5" xlinkHref="#d"></use>
      </g>
    </g>
  </svg>
);

export default JumprunIcon;
